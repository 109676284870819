'use client'
import React, { useEffect, useState } from 'react'
import { getCountryId, getCountryKey, getLanguageId } from 'helpers/country'
import { S3BucketJobseeker } from 'images'

import { Button, Modal, Select } from 'antd'
import styled from '@emotion/styled'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import styles from './index.module.scss'
import {
  configKey,
  getCookie,
  setCookie,
  removeCookie,
  userKey,
  redirectUrl
} from 'helpers/cookies'

import { useSearchParams } from 'next/navigation'
import { fetchUserSetting } from 'store/services/swtichCountry/userSetting'
import { countryConfig, languages } from 'helpers/country.base'

const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`
const SectionHeader = styled.div`
  color: #000;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 10px;
`
const Labels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  .item {
    display: flex;
    width: 160px;
    padding: 11px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--neutral-color-line-01-f-6-f-6-f-6, #f6f6f6);
    cursor: pointer;
    border: 1px solid transparent;

    &[data-checked='true'],
    &:hover {
      border-color: #2378e5;
      background-color: #f4f8fe;
    }

    img {
      /* width: 20px; */
      height: 20px;
      /* border-radius: 50%; */
    }
  }
`
const ModalHeader = styled.span`
  color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
  font-size: 24px;
  font-weight: 600;
`

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  button {
    font-size: 14px;
    font-weight: 400;
    height: auto;
    padding: 10px 24px;
    border-radius: 4px;
    border: 1px solid var(--brand-color-052378-e-5, #2378e5);
    line-height: 16px;
    &:first-child {
      color: #2378e5;
    }
  }
  @media screen and (max-width: 600px) {
    button {
      &:nth-child(2) {
        flex: 1;
      }
    }
  }
`
const Flag = styled.span`
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--neutral-color-line-02-e-8-e-8-ea, #e8e8ea);
`

const DownIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3536 3.64645C10.5488 3.84171 10.5488 4.15829 10.3536 4.35355L6.35355 8.35355C6.15829 8.54881 5.84171 8.54881 5.64645 8.35355L1.64645 4.35355C1.45118 4.15829 1.45118 3.84171 1.64645 3.64645C1.84171 3.45118 2.15829 3.45118 2.35355 3.64645L6 7.29289L9.64645 3.64645C9.84171 3.45118 10.1583 3.45118 10.3536 3.64645Z'
        fill='#B8B8B8'
      />
    </svg>
  )
}

const getInitialFormData = () => {
  return {
    lang: languages.find((v) => v.id === getLanguageId()).value,
    languageId: getLanguageId(),
    country: getCountryKey(),
    countryId: getCountryId()
  }
}
export const ChangeLangAndRegion = ({ show, onAction, zIndex = undefined }) => {
  const lang = useSharedData('DICTIONARY') || {}
  const [form, setForm] = useState(() => {
    return getInitialFormData()
  })

  const countryList = countryConfig.map((item) => ({
    ...item,
    label: item.name,
    value: item.key,
    flag:
      item.key === 'com'
        ? `${S3BucketJobseeker}/flags/flag_inter.jpg`
        : `${S3BucketJobseeker}/flags/${item.key.toUpperCase()}.png`
  }))

  const onClose = () => {
    onAction({ show: false })
  }
  const searchParams = useSearchParams()
  const referralCode = searchParams.get('referral_code')
  const invitedSource = searchParams.get('invited_source')

  useEffect(() => {
    if (show) {
      setForm(getInitialFormData())
    }
  }, [show])

  const handleSwitchNation = async (nation) => {
    const { country, lang, countryId, languageId } = nation || {}
    const isLocalDev = (url: string) => {
      return url?.includes('localhost') || url.includes('127.0.0.1')
    }

    removeCookie('location')

    const { origin, hostname, port, pathname, search } = window.location
    const isLocal = isLocalDev(hostname)
    const user = getCookie(userKey)
    const sessionid = getCookie('sessionid')
    let query = `/${lang}`

    const prefix = process.env.NEXT_PUBLIC_URL_PREFIX || ''
    console.log('prefix:', prefix)

    const newOrigin = `https://${prefix}${
      countryList.find((item) => item.key === form.country)?.url
    }`

    const referralCodeParams = referralCode ? `&referral_code=${referralCode}` : ''
    const invitedSourceParams = invitedSource ? `&invited_source=${invitedSource}` : ''

    if (sessionid) {
      try {
        await fetchUserSetting({ country_id: countryId, language_id: languageId })
      } catch (error) {
        console.log('error', error)
      }
    }

    if (origin.includes(newOrigin)) {
      // only language changed
      // the pathname is likely "/en-US/get-started"
      let restPath = pathname.split('/').slice(2).join('/')
      restPath = restPath ? `/${restPath}` : ''
      // store this in cookies. then the others link request server can take it to server
      setCookie(configKey, `${country}_${lang}`)
      window.location.href = newOrigin + query + restPath + location.search
      return
    }

    if (!isLocal && sessionid) {
      query +=
        `/changeLocale?` +
        `country=${country}` +
        `&${userKey}=${JSON.stringify(user)}` +
        `&${'sessionid'}=${sessionid}` +
        `&${redirectUrl}=${pathname.split('/').slice(2).join('/')}
        `
      query += referralCodeParams
      query += invitedSourceParams
    } else {
      query += '/' + pathname.split('/').slice(2).join('/') + search ? '?' + search.slice(1) : ''
    }

    window.location.href = newOrigin + query
  }

  return (
    <Modal
      zIndex={zIndex}
      title={
        <ModalHeader>
          {/* Country and Language */}
          {lang.comm?.countryAndLanguage || 'Country and Language'}
        </ModalHeader>
      }
      getContainer={() => document.body}
      open={show}
      destroyOnClose
      footer={<></>}
      wrapClassName={styles.modal_wrapper}
      onCancel={onClose}
    >
      <div className={styles.middleGap} />
      <Section>
        <SectionHeader>{lang.getStarted?.country || 'Country/Region'}</SectionHeader>
        <Labels className={styles.labelWrapper}>
          {countryList.map((v) => {
            const { id } = v
            return (
              <div
                className='item'
                key={id}
                data-checked={v.key === form.country}
                onClick={(e) => {
                  setForm((pre) => {
                    return { ...pre, country: v.key, countryId: v.id }
                  })
                }}
              >
                <Flag
                  style={{
                    border: 'none',
                    backgroundImage: `url(${v.flag})`
                  }}
                />
                <span style={{ flex: 1 }}>{v.label}</span>
              </div>
            )
          })}
        </Labels>
        <div className={styles.antSelectWrapper}>
          <Select
            // options={countryList}
            style={{ width: '100%', height: 42 }}
            value={form.country}
            onChange={(value, opt: any) => {
              const id = countryList.find((v) => v.value === value)?.id
              setForm((pre) => {
                return { ...pre, country: value, countryId: id }
              })
            }}
            suffixIcon={<DownIcon />}
          >
            {countryList.map((v) => {
              return (
                <Select.Option value={v.value} key={v.value}>
                  <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <Flag
                      style={{
                        backgroundImage: `url(${v.flag})`
                      }}
                    ></Flag>
                    {v.label}
                  </span>
                </Select.Option>
              )
            })}
          </Select>
        </div>
      </Section>

      <Section>
        <SectionHeader>{lang.switchCountry?.lang || 'Language'}</SectionHeader>
        <Labels className={styles.labelWrapper}>
          {languages.map((v) => {
            return (
              <div
                className='item'
                key={v.id}
                data-checked={v.value === form.lang}
                onClick={(e) => {
                  setForm((pre) => {
                    return { ...pre, lang: v.value, languageId: v.id }
                  })
                }}
              >
                {v.label}
              </div>
            )
          })}
        </Labels>
        <div className={styles.antSelectWrapper}>
          <Select
            options={languages}
            style={{ width: '100%', height: 42 }}
            value={form.lang}
            onChange={(value, opt: any) => {
              setForm((pre) => {
                return { ...pre, lang: value, languageId: opt.id }
              })
            }}
            suffixIcon={<DownIcon />}
          />
        </div>
      </Section>
      <Footer>
        <Button onClick={onClose} style={{ boxShadow: 'none' }}>
          {/* Cancel */}
          {lang.comm?.cancel || 'Cancel'}
        </Button>
        <Button
          type='primary'
          style={{ boxShadow: 'none', border: 'none' }}
          onClick={() => {
            onAction({ show: false })
            handleSwitchNation(form)
          }}
        >
          {lang.comm?.confirm || 'Confirm'}
          {/* Confirm */}
        </Button>
      </Footer>
    </Modal>
  )
}
