'use client'
import React, { useEffect, useCallback, useState } from 'react'
import { useRouter, useSearchParams, usePathname } from 'next/navigation'
import { postNotification, receiveNotification } from 'bossjob-remote/dist/clientStorage'
import { DefaultAvatar } from 'images'
import styles from './Hamburger.module.scss'
import classNames from 'classnames'
import { toggleMenu } from 'store/actions/navigationBar/toggleMenu'
import vip_user_icon_url from './vip_user_icon.png'

interface HamburgerProps {
  disabled?: boolean
  lang?: any
  userInfo?: any
  isLogin: boolean
  pageType?: string
}
const Hamburger = ({ disabled, lang, userInfo, isLogin }: HamburgerProps) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [openState, setOpenState] = useState(false)
  const pathname = usePathname()
  const handleShowMenu = useCallback(() => {
    // if (!openState) {
    //   // opening menu, disable scrolling of body
    //   document.body.style.position = 'fixed'
    //   document.body.style.width = '100%'
    // }

    // if (openState) {
    //   // closing menu, enable scrolling of body
    //   const scrollY = document.body.style.top
    //   document.body.style.position = ''
    //   document.body.style.top = ''
    //   // retrieve previous scroll position
    //   window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // }
    postNotification('DISPATCH', toggleMenu(!openState))

    // setOpenState(!openState)
  }, [toggleMenu, postNotification])

  // useEffect(() => {
  //   receiveNotification('DISPATCH_CLOSE', () => {
  //     setOpenState(false)
  //   })
  // }, [])

  const handleToGetStarted = () => {
    router.push('/get-started')
    if (searchParams.get('from') === 'sg-h5') {
      window?.gtag?.('event', 'sgh5_job_detail_get_started')
    }

    setOpenState(false)
  }

  return (
    <div className={styles.hamburgerWrapper}>
      {!isLogin && (
        <div
          className={styles.hamburgerWrapper_getStarted}
          style={{ marginRight: 25 }}
          onClick={handleToGetStarted}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_get_started'
          })}
        >
          {lang.getStarted}
        </div>
      )}

      {/* <div
        className={classNames([styles.mobile_menu_off, openState ? styles.mobile_menu_open : null])}
        onClick={disabled ? null : handleShowMenu}
      >
        <div id={styles.hamburgerMenu} className={openState ? styles.active : null}>
          {isLogin && !openState ? (
            !userInfo?.vip?.is_vip ? (
              <img
                src={userInfo?.avatar || DefaultAvatar}
                className={styles.profileAvatar}
                alt='avatar'
                style={{ width: '27px', height: '27px' }}
              />
            ) : (
              <div className={styles.vipAvatar}>
                <img
                  src={userInfo?.avatar || DefaultAvatar}
                  className={styles.profileAvatar}
                  alt='avatar'
                />
                <img
                  src={vip_user_icon_url.src}
                  width={17}
                  height={7}
                  alt=''
                  style={{ position: 'absolute', bottom: '-2px', right: 0 }}
                />
              </div>
            )
          ) : (
            <>
              <span />
              <span />
              <span />
            </>
          )}
        </div>
      </div> */}
      <div
        style={{
          position: 'relative'
          // marginLeft: 15
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          onClick={(e) => {
            e.stopPropagation()
            handleShowMenu()
          }}
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3 4.875C3 4.39175 3.3134 4 3.7 4L16.3 4C16.6866 4 17 4.39175 17 4.875C17 5.35825 16.6866 5.75 16.3 5.75L3.7 5.75C3.3134 5.75 3 5.35825 3 4.875ZM3 10C3 9.51675 3.3134 9.125 3.7 9.125H16.3C16.6866 9.125 17 9.51675 17 10C17 10.4832 16.6866 10.875 16.3 10.875H3.7C3.3134 10.875 3 10.4832 3 10ZM3 15.125C3 14.6417 3.3134 14.25 3.7 14.25L16.3 14.25C16.6866 14.25 17 14.6418 17 15.125C17 15.6082 16.6866 16 16.3 16L3.7 16C3.3134 16 3 15.6082 3 15.125Z'
            fill='black'
          />
        </svg>
      </div>
    </div>
  )
}

export default Hamburger
