/* eslint-disable react/prop-types */
import { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import Modal from 'components/Modal'
import { chatNowScript, switchJobScript } from '../abstractModels/ChatNow';
import { useRouter, usePathname } from 'next/navigation';
import interpreter from 'app/models/interpreters/chatNow'
import { formatTemplateString } from 'helpers/formatter';
import { fetchJobDetailService } from 'store/services/jobs/fetchJobDetail';
import RemotePrefModal from 'components/RemotePrefModal';
import { getDictionary } from 'get-dictionary';
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { postNotification, publishSharedData } from 'bossjob-remote/dist/clientStorage'
import { SgIdNotMatch } from 'images';
import styles from './index.module.scss'
import { Radio } from '@mui/material';
import { Button } from 'antd';
import { updateUserSgId } from 'store/services/jobs/fetchJobsCommunicated';
import Image from 'next/image';
import { getLang } from 'helpers/country';
import { isEmpty } from 'lodash-es'
import { getCookie, getSourceCookie } from 'helpers/cookies'

import CompleteProfileModal from 'app/components/CompleteProfileCard/Modal'


function redirectOnCancel(jobId) {
    if (location.pathname.includes('chat-redirect')) {
        if (jobId) {
            location.href = `${location.protocol}//${location.host}/job/chatredirect-${jobId}`
        } else {
            location.href = `${location.protocol}//${location.host}/jobs-hiring/job-search`
        }
    }
}
const ModalSwitch = (props) => {
    const { showModal, setShowModal, requestSwitch, loading, existedJob, selectedJob } = props
    const { jobDetail: translation } = useSharedData('DICTIONARY') ?? {}
    const { switchModal = {} } = translation || {}
    const pathname = usePathname()
    const router = useRouter()
    const isChatRedirect = pathname.includes('chat-redirect/')
    const redirectHome = () => { isChatRedirect && router.replace('/') }

    return <Modal
        showModal={showModal}
        handleModal={() => {
            setShowModal(false)
            redirectHome()
        }}
        firstButtonIsClose={false}
        handleFirstButton={e => {
            e?.stopPropagation()
            setShowModal(false)
            redirectHome()
        }}
        handleSecondButton={e => {
            e?.stopPropagation()
            requestSwitch(e)
        }}
        isFirstButtonLoading={loading}
        isSecondButtonLoading={loading}
        firstButtonText={switchModal?.btn1}
        secondButtonText={switchModal?.btn2}
        headerTitle={formatTemplateString(switchModal.title, selectedJob?.recruiter?.full_name ?? '')}
    >
        <p>
            {formatTemplateString(switchModal.content, existedJob.job_title ?? 'this job')}
        </p>
    </Modal >
}
const ModalCompleteFile = (props) => {
    const { showModal, setShowModal, handler } = props
    const [loading, setLoading] = useState(false)
    const { jobDetail: translation } = useSharedData('DICTIONARY') ?? {}
    const { completeProfileModal = {} } = translation || {}
    return <Modal
        showModal={showModal}
        handleModal={e => {
            // e?.stopPropagation()
            setShowModal(false)
        }}
        firstButtonIsClose={false}
        handleFirstButton={e => {
            e?.stopPropagation()
            setShowModal(false)
        }}
        handleSecondButton={e => {
            e?.stopPropagation()
            setLoading(true)
            handler?.()
        }}
        firstButtonText={completeProfileModal.btn1}
        secondButtonText={completeProfileModal.btn2}
        headerTitle={completeProfileModal.title}
        isFirstButtonLoading={loading}
        isSecondButtonLoading={loading}
    >
        <p>
            {completeProfileModal.content}
        </p>
    </Modal >
}
const AddSgIdModal = (props) => {
    const { showModal, setShowModal, handler } = props
    const config = useSharedData('CONFIG')
    const dictionary = useSharedData('DICTIONARY')
    const sgIdAdd = dictionary?.sgId?.add
    const selections = config?.user_identity_lists ?? []
    const [selected, setSelected] = useState(0)
    const [loading, setLoading] = useState(false)
    return <Modal
        showModal={showModal}
        headerClass={styles.notDisplay}
        className={styles.sgModal}

        handleModal={e => {
            // e?.stopPropagation()
            if (loading) {
                return
            }
            setShowModal(false)
            handler?.(false)
        }}
        firstButtonIsClose={false}
        handleFirstButton={e => {
            e?.stopPropagation()
            setShowModal(false)
            handler?.(false)
        }}
        handleSecondButton={e => {
            e?.stopPropagation()
            setShowModal(false)
            handler?.(true)
        }}

    >
        <h1>{sgIdAdd?.title}</h1>
        <p>
            {sgIdAdd?.description}
        </p>
        <div className={styles.items}>
            {selections.map(item => {

                return <div className={styles.item} key={item.id}>
                    <label className={styles.selection}>
                        <Radio checked={selected === item.id} onChange={() => setSelected(item.id)} />
                        <div>{item.value}</div>
                    </label>

                </div>
            })}
            <div className={styles.bottomButtons}>
                <Button
                    className={styles.cancel}
                    onClick={(e) => {
                        e?.stopPropagation()
                        setShowModal(false)
                        handler?.(false)
                    }}
                    loading={loading}
                >{sgIdAdd?.cancel}</Button>
                <Button
                    className={styles.submit}
                    disabled={!selected}
                    loading={loading}
                    onClick={e => {
                        e?.stopPropagation()
                        setLoading(true)
                        updateUserSgId({ id: selected })
                            .then(res => {
                                const newMe = res.data.data
                                publishSharedData('ME', newMe)
                                setTimeout(() => {
                                    setShowModal(false)
                                    handler?.(true)
                                }, 500)
                            })
                            .finally(() => setLoading(false))
                    }}
                >{sgIdAdd?.submit}</Button>
            </div>
        </div>
    </Modal >
}
const SgIdNotMatchModal = (props) => {
    const { showModal, setShowModal, handler, selectedJob } = props
    const dictionary = useSharedData('DICTIONARY')
    const notmatch = dictionary?.sgId?.notmatch
    const sgJobIds = selectedJob?.job_identity_restrictions || []
    const jobIdsMessage = sgJobIds.map(item => item?.value).join(', ') || ''
    const message = formatTemplateString(notmatch?.information, `[${jobIdsMessage}]`)

    return <Modal
        showModal={showModal}
        headerClass={styles.notDisplay}
        className={styles.sgNotMatchModal}
        handleModal={() => {
            setShowModal(false)
            handler?.()
        }}
        firstButtonIsClose={false}
    >
        <div className={styles.topback}>
            <Image className={styles.image} src={SgIdNotMatch} width={162} height={75}></Image>
        </div>
        <p>
            {message}
        </p>

        <div className={styles.bottomButtons}>
            <Button
                className={styles.cancel}
                onClick={(e) => {
                    e?.stopPropagation()
                    setShowModal(false)
                    handler?.()
                }}
            >{notmatch?.dismiss}</Button>
        </div>

    </Modal >
}
export const useChatNow = (jobDetail, source = "default") => {
    if (typeof window === 'undefined') {
        return [false]
    }
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showRemote, setShowRemote] = useState(false)
    const [showAddSgIdModal, setShowAddSgIdModal] = useState(false)
    const [showSgIdNotMatch, setShowSgIdNotMatch] = useState(false)
    const [chatData, setChatData] = useState()
    const [modalLoading, setModalLoading] = useState(false)
    const [showComplete, setShowComplete] = useState(false)
    const handleCompleteRef = useRef()
    const [existedJob, setExistedJob] = useState({})
    const [showCompleteUserProfile, setShowCompleteUserProfile] = useState(false)
    const router = useRouter()
    const dispatch = action => postNotification('DISPATCH', action)
    const showCompleteModal = useCallback(handler => {
        handleCompleteRef.current = handler
        setShowComplete(true)
    }, [])
    const [lang, setLang] = useState(jobDetail?.originDictionary ?? {})
    useEffect(() => {
        if (isEmpty(jobDetail?.originDictionary)) {
            getDictionary(getLang()).then(setLang)
        }
    }, [])
    const userData = useSharedData('ME') ?? {}
    const userDataRef = useRef(userData)
    const sessionid = getCookie('sessionid')
    useEffect(() => {
        userDataRef.current = userData
    }, [userData])
    const job_preferences = userData.job_preferences ?? []
    const config = useSharedData('CONFIG')
    const jobPreferencesRef = useRef(job_preferences)
    const [isPreferenceRemote, setIsPreferenceRemote] = useState(true)
    useEffect(() => {
        jobPreferencesRef.current = job_preferences
    }, [job_preferences])
    const remoteModalHandler = useRef()
    const remoteModalHandlerFail = useRef()
    const addSgIdModalHandler = useRef()
    const sgNotMatchModal = useRef()
    const skipRef = useRef(false)
    const context = {
        jobDetail,
        router,
        dispatch,
        showModal: chatData => {
            setChatData(chatData)
            setShowModal(true)
        },
        getUserData: () => userDataRef.current,
        getJobPreferences: () => jobPreferencesRef.current,
        getSkip: () => skipRef.current,
        showCompleteModal,
        showPreferenceModal: (remote, handler, fail) => {
            remoteModalHandler.current = handler
            remoteModalHandlerFail.current = fail
            setIsPreferenceRemote(remote)
            setShowRemote(true)
        },
        showAddSgIdModal: () => new Promise((res) => {
            setShowAddSgIdModal(true)
            addSgIdModalHandler.current = (finish) => {
                res(finish)
            }
        }),
        showSgIdNotMatchModal: () => new Promise((res) => {
            setShowSgIdNotMatch(true)
            sgNotMatchModal.current = () => {
                res()
            }
        }),
        showCompleteUserProfile: () => new Promise((res) => {
            setShowCompleteUserProfile(true)
            res()
        }),
        skip: false,
        showLogin: () => postNotification('SHOW_LOGIN_MODAL'),
        source,
        isLogin: () => !!sessionid
    }

    const requestSwitch = useCallback(() => {
        setModalLoading(true)
        interpreter(switchJobScript(chatData)).run(context).finally(() => {
            setModalLoading(false)
        })
    }, [interpreter, context, chatData])

    useEffect(() => {
        const jobId = jobDetail?.chat?.job_id
        if (jobId && jobId !== jobDetail.id) {
            fetchJobDetailService({ jobId }).then((data) => {
                setExistedJob(data?.data?.data)
            })
        }
    }, [jobDetail?.id])

    const changeJobModal = useMemo(() => {
        return <>
            <ModalCompleteFile
                showModal={showComplete}
                setShowModal={setShowComplete}
                handler={() => handleCompleteRef.current?.()}
            />
            <AddSgIdModal
                showModal={showAddSgIdModal}
                setShowModal={setShowAddSgIdModal}
                handler={(result) => addSgIdModalHandler.current?.(result)}
            />
            <SgIdNotMatchModal
                selectedJob={jobDetail}
                showModal={showSgIdNotMatch}
                setShowModal={setShowSgIdNotMatch}
                handler={(result) => sgNotMatchModal.current?.(result)}
            />
            <ModalSwitch
                existedJob={existedJob}
                showModal={showModal}
                setShowModal={setShowModal}
                loading={modalLoading}
                requestSwitch={requestSwitch}
                selectedJob={jobDetail}
                chatData={chatData}
            />
            <RemotePrefModal
                isRemote={isPreferenceRemote}
                lang={lang}
                arrangementDisabled={true}
                open={showRemote}
                configs={config}
                onClose={() => {
                    setShowRemote(false)
                    skipRef.current = true

                    // remoteModalHandlerFail.current?.([])
                    remoteModalHandler.current?.()
                }}
                onFinish={(value) => {
                    setShowRemote(false)
                    remoteModalHandler.current?.()
                    jobPreferencesRef.current = value.job_preferences ?? []
                }}
            />
            <CompleteProfileModal
                open={showCompleteUserProfile}
                onOk={() => setShowCompleteUserProfile(false)}
                onCancel={() => setShowCompleteUserProfile(false)}
                lang={lang}
                jobDetail={jobDetail}
                userDetail={userData}
            />
        </>
    }, [showModal, showRemote, setShowModal, modalLoading, jobDetail, requestSwitch, showComplete, setShowComplete, isPreferenceRemote, setShowRemote, showCompleteUserProfile, setShowCompleteUserProfile])
    const chatNow = () => {
        setLoading(true)
        return interpreter(chatNowScript())
            .run(context)
            .finally(() => setLoading(false))
    }
    return [loading, chatNow, changeJobModal, userData]
}
export default useChatNow
