'use client'
import dynamic from 'next/dynamic';
import 'bossjob-login/lib/style.css'
import { publishSharedData } from 'bossjob-remote/dist/clientStorage';
if (typeof window !== 'undefined') {
  publishSharedData('ASSETS_BUCKET_URL', process.env.S3_BUCKET_URL)
  publishSharedData('BOSSJOB_JOBSEEKER_ENDPOINT', process.env.BOSSJOB_JOBSEEKER_ENDPOINT)
  publishSharedData('CLIENT_ID', process.env.CLIENT_ID)
  publishSharedData('CLIENT_SECRET', process.env.CLIENT_SECRET)
  publishSharedData('CF_TOKEN_ID', process.env.CF_TOKEN_ID)
  publishSharedData('ONE_CF_TOKEN_ID', process.env.ONE_CF_TOKEN_ID)
  publishSharedData('GA_TRACKING_ID', process.env.GA_TRACKING_ID)
  publishSharedData('ONE_CF_TOKEN_ID', process.env.ONE_CF_TOKEN_ID)
  publishSharedData('BOSSHUNT_URL', process.env.BOSSHUNT_URL)
}

export const LoginModal = dynamic(() => import('bossjob-login/lib/src/remote-login-modal'), { ssr: false })
export const GetStarted = dynamic(() => import('bossjob-login/lib/src/remote-get-started'), { ssr: false })
export const Connect = dynamic(() => import('bossjob-login/lib/src/remote-login-connect'), { ssr: false })
export const EmailPage = dynamic(() => import('bossjob-login/lib/src/remote-login-email'), { ssr: false })
export const PhonePage = dynamic(() => import('bossjob-login/lib/src/remote-login-phone'), { ssr: false })
export const MagicPage = dynamic(() => import('bossjob-login/lib/src/remote-login-magic'), { ssr: false })
export const RedirectApp = dynamic(() => import('bossjob-login/lib/src/remote-login-redirect-app'), { ssr: false })
export const SocialCallback = dynamic(() => import('bossjob-login/lib/src/remote-social-auth-callback'), { ssr: false })