'use client'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { useRouter, usePathname } from 'next/navigation'
import { isMobile } from 'react-device-detect'

/* Style */
import styles from '../Header.module.scss'
/* components */
import Link from 'components/Link'
import Text from 'components/Text'
import { useSharedData } from 'bossjob-remote/dist/hooks'
/* Images */
import { DefaultAvatar } from 'images'
// import dynamic from 'next/dynamic'
import CountryLanguage from '../PublicHeader/countryLanguage'
// import Dropdown from 'antd/es/dropdown'
// import DropDownMenu from '../Common/DropDownMenu'
import vip_user_icon_url from './vip_user_icon.png'
import dynamic from 'next/dynamic'
const base64DefaultAvatar =
  'data:image/webp;base64,UklGRpoBAABXRUJQVlA4WAoAAAAQAAAALwAALwAAQUxQSD4AAAABT6CgbRuGP+b+jUREZB5JAkWxrVRAAjJgg08DCxjBCEQwgra+W11F9H8CSrB1q7C+nkti558wTKnEZm/suFZQOCA2AQAAUAkAnQEqMAAwAD6JNpZHpSKiITHWbfigEQloAKwywkPno3mRWTtHRANuzdoAI9DOkbLEvbwD4DlpYYVck2PSPNz8VA13iNzGN/iy8NVbaw4nKYAA/u/YP+i7fl/zq7fG+Y4L8UPHMP/xDgpN57//xUXRCaada7X2Jqu3tA3lRQc9qAjeoUtiilX40bN5u12QNEbD7VfUdSbn1Uzt33h99JQQXpaBju+9OQ24VimFveCzbveLLRHyLjmkS2ZuufJ+6l8zTjSm9oDpA+TmVdZBA85eVU+hqrDB4SUgMi06TTFPUwOf15QAR8IZEcFf/8MSIjwOqr22R+4wodYDSPmPq0VHmBjgeFV8yw/1hzLSL8NijwFM8CK5lR++xwgqR+cput0l1jUB/AK+vwNnLkvULLJhrc4AAA=='

interface IProps {
  langKey: string
  lang: any
  userInfo: any
  initUnread?: number
  countryKey: string
}

const Dropdown = dynamic(() => import('antd/es/dropdown'), { ssr: true })
const DropDownMenu = dynamic(() => import('../Common/DropDownMenu'), { ssr: false })

const NavRight = (props: IProps) => {
  const { langKey, lang, userInfo, initUnread = 0, countryKey } = props
  const router = useRouter()
  const pathname = usePathname()
  const { manageResume, Chat } = lang || {}
  const unread =
    typeof window !== 'undefined' ? useSharedData('CHAT_TOTAL_UNREAD') ?? initUnread : initUnread
  const totalUnread = unread
  const [showUnCompletedDot, setShowUnCompletedDot] = useState(false)

  const [open, setOpen] = useState(false)
  const [unreadNotification, setUnreadNotification] = useState(0)

  const isVip = useMemo(() => userInfo?.vip?.is_vip, [userInfo])
  const { avatar } = userInfo
  useEffect(() => {
    if (userInfo?.job_preferences) {
      const hasJobPreferences = userInfo.job_preferences?.length > 0
      setShowUnCompletedDot(!userInfo?.is_profile_completed || !hasJobPreferences)
    }
  }, [userInfo])
  const Avatar = (
    <img
      src={avatar ?? base64DefaultAvatar}
      // placeholder={base64DefaultAvatar}
      className={styles.profilePlaceHolder}
      width={35}
      height={35}
      alt='avatar'
      style={{ width: 35, height: 35 }}
      onError={(e) => {
        console.log({ userInfoOnError: e })
        ;(e.target as HTMLInputElement).src = DefaultAvatar
      }}
    />
  )

  return (
    <ul className={styles.headerLinksList}>
      <React.Fragment>
        <li className={styles.headerLink} style={{ flexDirection: 'row', alignItems: 'center' }}>
          <div className={styles.countryItem}>
            {pathname !== '/chat/[chat_id]' ? (
              <Link title='Jobs' to={'/' + langKey + '/chat/list'}>
                <span style={{ fontSize: 16, color: '#4e5969' }}>{Chat}</span>
              </Link>
            ) : (
              <span
                className={classNames([styles.headerLinkTextCurrentPage])}
                style={{ fontSize: 16, color: '#4e5969' }}
              >
                {Chat}
              </span>
            )}

            {totalUnread ? (
              <span>
                <span className={styles.unread}>
                  {Number(totalUnread) > 99 ? '99+' : totalUnread}
                </span>
              </span>
            ) : null}
          </div>
        </li>
        <li className={classNames([styles.headerLink, styles.headerLinkLogin])}>
          <div className={styles.countryItem}>
            <span
              style={{
                fontSize: '16px',
                color: '#353535',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                lineHeight: '16.5px'
              }}
              className={classNames(showUnCompletedDot ? styles.unCompleted : '')}
              onClick={
                !pathname.includes('/manage-profile')
                  ? () => {
                      router.push('/' + langKey + '/manage-profile')
                      // userInfo?.is_profile_completed
                      //   ? router.push('/' + langKey + '/manage-profile')
                      //   : router.push('/' + langKey + '/jobseeker-complete-profile')
                    }
                  : undefined
              }
            >
              {manageResume}
            </span>
          </div>
        </li>
        <li className={styles.headerLink}>
          <div className={styles.countryItem}>
            <CountryLanguage countryKey={countryKey} />
          </div>
        </li>
        <li className={styles.headerLink} style={{ paddingLeft: '0' }}>
          <Dropdown
            placement='bottomRight'
            {...(isMobile && {
              overlayStyle: {
                right: 5
              }
            })}
            trigger={['hover']}
            open={open}
            onOpenChange={(open) => {
              setOpen(open)
            }}
            forceRender
            overlayClassName={styles.authUserMenu}
            dropdownRender={() => {
              return (
                <DropDownMenu
                  ref={undefined}
                  langKey={langKey}
                  lang={lang}
                  pathname={pathname}
                  setDorpDownOpen={setOpen}
                  onUnreadChange={setUnreadNotification}
                />
              )
            }}
          >
            <div
              className={classNames(
                styles.profileProtectedWrapper,
                unreadNotification ? styles.profileHasUnread : ''
              )}
            >
              {isVip ? (
                <div className={styles.vipAvatar}>
                  <img
                    src={vip_user_icon_url.src}
                    width={23}
                    height={9}
                    alt=''
                    style={{ position: 'absolute', bottom: '-1px', right: 0, width: 23, height: 9 }}
                  />
                  {Avatar}
                </div>
              ) : (
                Avatar
              )}
            </div>
          </Dropdown>
        </li>
      </React.Fragment>
    </ul>
  )
}

export default NavRight
